import Footer from "../../Footer/Footer";
// import NavBar from "../../Header/Navbar";
// import Customer from "./Customer/Customer";
import Gallery from "./Gallery/Gallery";
import Parallax from "./Parallax/Parallax";
import Productlist from "./Productslider/Productlist";
import Property from "./Properties/Property";
// import Slider from "./Slider/Slider";

const Home = () => {
  return (
    <>
      {/* <NavBar /> */}
      <Parallax />
      <Property />
      <Gallery />
      {/* <Customer /> */}
      {/* <Slider /> */}
      <Productlist />
      <Footer />
    </>
  );
};
export default Home;
