import f1cover from "../../../Images/f1front.png";
import f11 from "../../../Images/f1side.png";
import f12 from "../../../Images/f1bag.png";
import f13 from "../../../Images/f1perspective.png";
import freecover from "../../../Images/freefront.png";
import free1 from "../../../Images/freeside.png";
import free2 from "../../../Images/freebag.png";
import free3 from "../../../Images/free3D.png";
import r2cover from "../../../Images/r2FRONT.png";
import r21 from "../../../Images/r2SIDE-.png";
import r22 from "../../../Images/rallybag.png";
import r23 from "../../../Images/r2perspective.png";
import r3cover from "../../../Images/r3front.png";
import r31 from "../../../Images/race3side.png";
import r32 from "../../../Images/race1bag.png";
import r33 from "../../../Images/race3perspectiver.png";
import invcover from "../../../Images/invfront.png";
import inv1 from "../../../Images/invside.png";
import inv2 from "../../../Images/inovabag.png";
import inv3 from "../../../Images/inv3d.png";
import rpbluecover from "../../../Images/rpblue-front.png";
import rp1 from "../../../Images/r2plusside-blue.png";
import rp2 from "../../../Images/rallyplusbag.png";
import rp3 from "../../../Images/r2plusperspectice-blue.png";
import zycover from "../../../Images/zyfront.png";
import zy1 from "../../../Images/zside.png";
import zy2 from "../../../Images/z3d.png";
import zy3 from "../../../Images/zylonbag.png";
import sense2cover from "../../../Images/s2front.png";
import sense21 from "../../../Images/s2side.png";
import sense22 from "../../../Images/sense2pack.png";
import sense23 from "../../../Images/s2perspective.png";
import sense3cover from "../../../Images/s3front.png";
import sense31 from "../../../Images/s3side.png";
import sense32 from "../../../Images/sense3bag.jpg";
import sense33 from "../../../Images/s3perspectice.png";
import solocover from "../../../Images/soloFRONT.png";
import solo1 from "../../../Images/soloSIDE.png";
import solo2 from "../../../Images/soloPERSPECTIVE.png";
import solo3 from "../../../Images/soloBACK.png";

let Data = [
  {
    id: "1",
    title: "FREEDOM",
    coverimg: freecover,
    Image: [freecover, free1, free2, free3],
    categoryId: "A",
    desc: "szdexgtjyhkdalkrujhtgb;aeirhtgvma;ec,tare;hct;aerht",
  },
  {
    id: "2",
    title: "INOVA 6",
    coverimg: invcover,
    Image: [invcover, inv1, inv2, inv3],
    categoryId: "A",
    desc: "",
  },
  {
    id: "3",
    title: "SENSE 2",
    coverimg: sense2cover,
    Image: [sense2cover, sense21, sense22, sense23],
    categoryId: "A",
    desc: "",
  },
  {
    id: "4",
    title: "SENSE 3",
    coverimg: sense3cover,
    Image: [sense3cover, sense31, sense32, sense33],
    categoryId: "A",
    desc: "",
  },
  {
    id: "5",
    title: "FORMUL 1",
    coverimg: f1cover,
    Image: [f1cover, f11, f12, f13],
    categoryId: "B",
    desc: "",
  },
  {
    id: "6",
    title: "ZYLONE",
    coverimg: zycover,
    Image: [zy1, zy2, zy3, zycover],
    categoryId: "B",
    desc: "",
  },
  {
    id: "7",
    title: "RACE 3",
    coverimg: r3cover,
    Image: [r3cover, r31, r32, r33],
    categoryId: "B",
    desc: "",
  },
  {
    id: "8",
    title: "RALLY 2",
    coverimg: r2cover,
    Image: [r2cover, r21, r22, r23],
    categoryId: "B",
    desc: "",
  },
  {
    id: "9",
    title: "RALLY 2 PLUS",
    coverimg: rpbluecover,
    Image: [rpbluecover, rp1, rp2, rp3],
    categoryId: "B",
    desc: "",
  },

  {
    id: "10",
    title: "SOLO 1",
    coverimg: solocover,
    Image: [solocover, solo1, solo2, solo3],
    categoryId: "B",
    desc: "",
  },
];
export default Data;
