import img1 from "../../../../Images/formul1.jpg";
import img2 from "../../../../Images/formul3.jpg";
import img3 from "../../../../Images/formul2.jpg";
import img4 from "../../../../Images/formul4.jpg";

const Gallery = () => {
  return (
    <>
      <div className="lg:w-8/12 w-10/12 mx-auto">
        <div className="flex flex-row my-5 gap-x-4 md:gap-x-7">
          <div className="basis-3/4 ">
            <img
              src={img1}
              alt="gallery1"
              className="rounded-xl md:rounded-3xl min-h-[92px] xl:min-h-[232px]"
            />
          </div>
          <div className="basis-1/4 rounded-3xl">
            <img
              src={img4}
              alt="gallery4"
              className="rounded-xl md:rounded-3xl min-h-[92px] md:min-h-[172px] lg:min-h-[184px] xl:min-h-[232px] 2xl:min-h-[348px]"
            />
          </div>
        </div>
        <div className="flex flex-row my-5 gap-x-4 md:gap-x-7">
          <div className="basis-1/4 rounded-3xl mb-5">
            <img
              src={img2}
              alt="gallery2"
              className="rounded-xl md:rounded-3xl min-h-[92px] md:min-h-[172px] lg:min-h-[184px] xl:min-h-[232px] 2xl:min-h-[348px]"
            />
          </div>
          <div className="basis-3/4 grid justify-end">
            <img
              src={img3}
              alt="gallery3"
              className="rounded-xl md:rounded-3xl min-h-[92px] xl:min-h-[232px]"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
