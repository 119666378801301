// import Header from "../../../Header/Header";
import Parallaxbg from "./Parallaxbg";
const Parallax = () => {
  return (
    <>
      <Parallaxbg />
    </>
  );
};

export default Parallax;
