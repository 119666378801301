import React from "react";
import "../../../App/App.css";
const Parallaxbg = () => {
  return (
    <>
      <div className="parallax z-0">
        <div className="bg-indigo-600 bg-opacity-0 text-black p-8 text-center absolute w-full px-auto py-[15%]">
          <p className="text-4xl md:text-6xl font-semibold text-white italic">
            A FRESH NEW SHAVE
          </p>
        </div>
      </div>
    </>
  );
};

export default Parallaxbg;
