import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../../../styles.css";
import { Navigation } from "swiper/modules";
import ListData from "./ListData";
import { Swiper, SwiperSlide } from "swiper/react";
import invtype from "../../../../Images/invlogotype.png";

const Productlist = () => {
  return (
    <>
      <div className="container mx-auto text-center mb-14">
        <p className="text-4xl font-semibold text-center my-5">Our Products</p>
        <Swiper
          slidesPerView={5}
          spaceBetween={0}
          loop={true}
          breakpoints={{
            0: {
              slidesPerView: 2,
            },
            550: {
              slidesPerView: 3,
            },
            1280: {
              slidesPerView: 5,
            },
          }}
          // pagination={{
          //   clickable: true,
          // }}
          navigation={true}
          modules={[Navigation]}
          className="mySwiper text-white mx-auto"
        >
          {ListData?.map((elem) => {
            return (
              <SwiperSlide key={elem.id} className="p-4">
                <div className="w-full flex flex-col items-center text-center">
                  <img
                    src={elem.Image}
                    className="w-4/5 transition ease-in-out hover:-translate-y-1 hover:scale-110"
                  />
                  {/* <p className="text-base text-black mt-4 font-semibold">
                    {elem.name}
                  </p> */}
                  <img className="w-2/5" src={invtype}></img>
                </div>
                {/* <div className="absolute -inset-full z-10 transform -skew-x-12 bg-gradient-to-r from-transparent to-white opacity-40 hover:animate-shine w-1/2"></div> */}
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
};
export default Productlist;
