import Footer from "../../Footer/Footer";
// import NavBar from "../../Header/Navbar";
import banner from "../../../Images/product.JPG";
import ProductData from "./ProductData";
import Category from "./Categorydata";
import { Link } from "react-router-dom";

const Products = () => {
  return (
    <>
      {/* <NavBar /> */}
      {Category?.map((item) => {
        return (
          <div key={item.id}>
            <div className="mb-10">
              <img className="w-full" src={item.banner}></img>
            </div>
            <div className="w-3/5 mx-auto text-center">
              <p className="text-2xl font-medium block">{item.name}</p>
              <p className="text-lg font-normal">{item.desc} </p>
              <div className="grid grid-cols-4 mx-auto text-center my-8 bg-gray-100 p-4 rounded-lg gap-5">
                {ProductData?.map((elem, index) => {
                  return elem.categoryId === item.id ? (
                    <div className="" key={index}>
                      <Link
                        className="grid justify-items-center"
                        to={`/products/${elem.id}`}
                      >
                        <img
                          src={elem.coverimg}
                          className="block w-3/4 transition ease-in-out hover:-translate-y-1 hover:scale-110"
                        ></img>
                        <p className="text-sm lg:text-base font-semibold block text-white text-center my-4 bg-black hover:bg-white hover:border hover:border-black hover:text-black rounded-[5px] px-8 py-1">
                          {elem.title}
                        </p>
                      </Link>
                    </div>
                  ) : (
                    <></>
                  );
                })}
              </div>
              {/* <div className="flex flex-row mx-auto text-center my-8">
                {ProductData?.map((elem, index) => {
                  return elem.categoryId === item.id ? (
                    <div className="basis-1/3" key={index}>
                      <Link
                        className="grid justify-items-center"
                        to={`/products/${elem.id}`}
                      >
                        <img src={elem.coverimg} className="block w-3/5 "></img>
                        <p className="text-base font-semibold block text-center my-4">
                          {elem.title}
                        </p>
                      </Link>
                    </div>
                  ) : (
                    <></>
                  );
                })}
              </div> */}
            </div>
          </div>
        );
      })}
      ;
      <Footer />
    </>
  );
};
export default Products;
