import Footer from "../../Footer/Footer";
// import NavBar from "../../Header/Navbar";
import dorcoabout from "../../../Images/dorco-about-top.jpg";

const About = () => {
  return (
    <>
      {/* <NavBar /> */}
      <img className="w-10/12 lg:w-3/5 mx-auto" src={dorcoabout}></img>
      <p className="w-11/12 lg:w-3/5 mx-auto text-lg md:text-2xl p-5 mt-3 lg:mt-8 mb-12">
        WE SHAVE AGAINST THE GRAIN We make craft, small batch soap, shaving
        products, lotions, and balms with natural ingredients for men who want
        the best and shave against the grain. We are a daddy-daughter team
        dedicated to producing American made, high quality, small batch grooming
        products for men. Okay, Audrey just sits around and looks cute while I
        do all the real work. But hopefully, she’ll join the family business in
        a more meaningful way in the future. Our mission is to improve men's
        grooming, one person at a time, by offering the best
        possible grooming products at reasonable prices. We want to turn your
        daily chore into a daily indulgence. We want you to look your best,
        smell your best, & feel better by shaving against the grain with WSP. We
        want to meet and exceed your expectations. To get you your order as fast
        as reasonably possible, to answer your questions quickly, and to ensure
        that you are happy with your purchase.
      </p>
      <Footer />
    </>
  );
};
export default About;
